<template>
  <!-- markers START -->
  <ul id="results-list">
    <li
      v-for="(marker, i) in markers"
      :key="i"
      v-on:click='centerMapToMarker(marker.id); trigger()'
    >
      <h2 v-html="marker.locationData.headline"></h2>

      <p>
        <span v-html="`${marker.locationData.street} ${marker.locationData.houseNumber}`"></span>
      </p>
      <p>
        <span>
          <span v-html="`${marker.locationData.zip} ${marker.locationData.city}`"></span>
          <span
            v-if="marker.locationData.finalDistance"
            v-html="` (${marker.locationData.finalDistance})`"
          ></span>
        </span>
      </p>

      <p class="active" v-if="marker.locationData.comingsoon === 0">
        <span v-html="$t('location.open')"></span>
      </p>
      <p class="inactive" v-else>
        <span v-html="$t('location.closed')"></span>
      </p>

      <router-link
      :to="`/branch-finder/${marker.id}`"
        class="list-permalink"
      ></router-link>
    </li>
  </ul>
  <!-- markers END -->
</template>

<script>
export default {
  name: 'markers',
  props: {
    markers: {
      type: Array,
      default: () => [],
    },

    currentLocation: {
      type: Object,
      default: () => {},
    },

    centerMapToMarker: {
      type: Function,
    },

    trigger: {
      type: Function,
    },
  },
};
</script>
